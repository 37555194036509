.section-quote {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 1rem 2rem 1rem 2rem;
	color: var(--main-color-lila);
}

.section-quote p {
	font-family: "Dancing Script", sans-serif;
	font-size: 1.5rem;
	font-weight: 100;
	font-style: italic;
	text-align: center;
	max-width: var(--max-content-width);
	padding: 0 5vw 0 5vw;
}