.news-box-bg-image::before {
    content: "";
    position: absolute;
    top: 30%;
    left: 20%;
    width: 60%;
    height: 60%;
    opacity: 0.1;
    background-image: url("../../resources/Logo-512.rainbow.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -1;
}

.news-box-2-rows {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 3em 0 3em;
}

.news-box-header-list {
    list-style-type: none;
    align-content: start;
    padding: 1em 0 1em 0;
}

.news-box-header-list li {
    list-style-type: none;
	display: flex;
    padding-left: 0;
}

.news-box-header-list-span {
	font-weight: 400;
	min-width: 10em;
	flex: initial;
}

.news-box-header-list-span-central {
	font-weight: 400;
	min-width: 10em;
	flex: initial;
    text-align: center;
}

.news-box-2-rows div p {
    font-weight: 100;
    text-align: justify;
}

