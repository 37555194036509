.main-navbar {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.main-navbar-box {
	max-width: var(--max-content-width);
	min-width: 340px;
	font-family: "Bubblegum Sans", sans-serif;
	font-size: 1.8rem;
	font-weight: 400;
	font-display: block;
	padding: 0;
}

.main-navbar-color-red {
	color: var(--main-color-red) !important;
}

.main-navbar-color-lila {
	color: var(--main-color-lila) !important;
	--bs-navbar-active-color: var(--main-color-lila);
	--bs-nav-link-hover-color: var(--main-color-lila);
	--bs-nav-link-color: var(--main-color-lila);
}

.main-navbar-color-green {
	color: var(--main-color-green) !important;
}

.main-navbar-color-orange {
	color: var(--main-color-orange) !important;
}

.main-navbar-color-blue {
	color: var(--main-color-blue) !important;
}