.title-banner {
	background-color: var(--main-color-green);
	background-size: cover;
	width: 100%;
	min-height: 4rem;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	color: var(--main-color-light);
	padding: 1rem 0 1.5rem 0;
	font-weight: 400;
	font-size: 1.2rem;
	font-style: normal;
}

.title-banner-block {
	max-width: var(--max-content-width);
	min-width: 60vw;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 0 5vw 0 5vw;
}

.title-banner-block h1 {
	margin: 0 0 10px 0;
}

.title-banner-block a {
	color: var(--main-color-orange);
	font-size: 0.8rem;
	font-weight: 300;
	margin: 0;
}

.title-banner-block p {
	font-size: 0.8rem;
	font-weight: 300;
	margin: 0;
}
