.footbar-box { 
	height: 48px;
	color: var(--main-color-light);
	display: flex;
	justify-content: center;
	align-items: center;
}

.footbar-column {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 10px;
	color: var(--main-color-light);
	font-size: 12px;
	font-weight: 400;
	margin: 0 20px 20px;
	text-align: start;
}

.footbar-column-align-start {
	flex: 2 1 0px;
	justify-content: flex-start;
	align-items: center;
}

.footbar-column-align-end {
	flex: 1 1 0px;
	justify-content: flex-end;
	align-items: center;
}

.footbar-column a {
	color: var(--main-color-green);
	text-decoration: none;
	font-size: 12px;
	font-weight: 400;
	margin: 0;
	text-align: end;
}