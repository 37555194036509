:root {
	--main-color-lila: #6845ad;
	--main-color-green: #429600;
	--main-color-yellow: #ffef2f;
	--main-color-orange: #ffa300;
	--main-color-red: #ff453a;
	--main-color-blue: #32a8d7;
	--main-color-light: #f0f0f0;
	--main-color-dark: #282c34;
	--main-background-color-light: #ffffff;
	--main-background-color-dark: #282c34;
	--main-background-color-darker: #22232b;
	--max-content-width: 1140px;
}

body {
	margin: 0;
	font-family: "Bubblegum Sans", sans-serif;
	font-weight: 400;
	font-style: normal;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.bubblegum-sans-regular {
	font-family: "Bubblegum Sans", sans-serif;
	font-weight: 400;
	font-style: normal;
}

* {
	--sapFontFamily: "Bubblegum Sans", sans-serif;
	--sapFontSize: .875rem;
	--sapButton_Lite_TextColor: var(--main-color-lila);
	--sapButton_Lite_Hover_TextColor: var(--main-color-orange);
	--sapButton_TextColor: var(--main-color-lila);
	--sapButton_Hover_TextColor: var(--main-color-orange);
};
