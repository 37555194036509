.common-page {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 3rem 0 2rem 0;
}

.common-page-box {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	max-width: var(--max-content-width);
	min-width: 60vw;
	width: 100%;
	padding: 0 5vw 3rem 5vw;
}

.common-page-center-alignment {
	align-items: center;
	padding: 0 5vw 0 5vw;
}

.common-page-signature {
	font-family: "Dancing Script", sans-serif;
	font-size: 2em !important;
	font-weight: 400;
	font-display: block;
	margin: 0 0 0.5em 0;
}

.common-page-box h1 {
	font-size: 2em;
	font-weight: 400;
	margin: 10px 0 2rem 0;
	text-align: start;
}

.common-page-box h2 {
	font-size: 1.6em;
	font-weight: 200;
	margin: 10px 0 1rem 0;
	text-align: start;
}

.common-page-box p {
	font-size: 1.2em;
	font-weight: 100;
	text-align: start;
}

.common-page-box a {
	text-decoration: none;
	color: var(--main-color-dark);
}

.common-page-box a:hover {
	text-decoration: underline;
}

.common-page-box ul {
	font-size: 1.2em;
	font-weight: 100;
	list-style-type: circle;
	margin: 0.5rem 0 1rem 0;
	text-align: start;
}

.common-page-list-type-none {
	list-style-type: none;
	display: flex;
	padding-left: 0;
}

.common-page-grid-flex-box {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(calc(var(--max-content-width)/3), 1fr));
	grid-gap: 2rem;
}

.common-text-bold {
	font-weight: 400;
}

.common-text-header-2 {
	font-size: 1.6em;
	font-weight: 200;
}

.common-link-lila {
	text-decoration: none;
	font-weight: 400;
	color: var(--main-color-lila) !important;
}

.common-link-green {
	text-decoration: none;
	font-weight: 400;
	color: var(--main-color-green) !important;
}

.common-text-lila {
	color: var(--main-color-lila);
}

.common-text-blue {
	color: var(--main-color-blue) !important;
}

.common-text-green {
	color: var(--main-color-green);
}

.common-text-orange {
	color: var(--main-color-orange) !important;
}

.common-text-red {
	color: var(--main-color-red) !important;
}