.App {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	font-weight: 400;
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.App-body {
	background-color: var(--main-background-color-light);
	padding: 20px;
}

.App-footer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.App-footer-bg-0 {
	background-color: var(--main-background-color-dark);
}

.App-footer-bg-1 {
	background-color: var(--main-background-color-darker);
}

#error-page {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
}