/* PageFooter.css */
.footer-box {
	color: var(--main-color-light);
	padding: 20px 0 40px 0;
	display: flex;
	flex-flow: row wrap;
	flex-direction: raw;
	align-items: flex-center;
	justify-content: center;
}

.footer-column {
	flex: 1 1 auto;
	margin: 0 40px;
}

.footer-column h3 {
	color: var(--main-color-green);
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 0.5em;
	text-align: start;
}

.footer-column h4 {
	color: var(--main-color-green);
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 0.5em;
	text-align: start;
}

.footer-column p {
	color: var(--main-color-light);
	font-size: 14px;
	font-weight: 300;
	text-align: start;
}

.footer-column a {
	color: var(--main-color-light);
	text-decoration: none;
}

.footer-column a:hover {
	text-decoration: underline;
}