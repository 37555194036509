.registration-page-grid-box {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 0.5rem;
	grid-auto-rows: minmax(1.5rem, auto);
}

.registration-page-grid-box div {
	font-size: 1.2em;
	font-weight: 100;
	text-align: start;
	align-content: center;
}

.registration-page-header {
	font-weight: 400 !important;
	align-content: start !important;
}

.registration-page-main-row {
	align-content: center !important;
}

.registration-joined-cell {
	grid-row: 2 / 4;
	grid-column: 3;
}

.registration-cell-1 {
	text-align: center !important;
	align-content: center !important;
}

.registration-joined-cell-horizontal {
	grid-row: 1;
	grid-column: 2 / 4;
}

.registration-text-orange {
	color: var(--main-color-orange);
	font-weight: 600 !important;
}

.registration-text-green {
	color: var(--main-color-green);
	font-weight: 600 !important;
}