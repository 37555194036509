.home-page {
	display: flex;
	flex-direction: raw;
	align-items: center;
	justify-content: center;
	padding: 3rem 0 3rem 0;
	background-color: var(--main-background-color-light);
}

.home-page-alignment {
	align-items: center;
	padding: 0 5vw 0 5vw;
}

.home-page-alignment h2 {
	font-size: 2em;
	font-weight: 400;
	margin-bottom: 0.8em;
	text-align: start;
}

.home-page-alignment p {
	font-size: 1.2em;
	font-weight: 100;
	text-align: center;
}

/* Foto von Steve Johnson: https://www.pexels.com/de-de/foto/mehrfarbige-abstrakte-malerei-1266808/ */
.home-header {
	background: url("../../resources/Nepal-himmel.png") no-repeat left bottom;
    background-size: cover;
	background-color: var(--main-color-green);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	min-height: 100%;
	padding: 20px 0 20px 0;
	font-family: "Bubblegum Sans", sans-serif;
	font-size: calc(10px + 2vmin);
	font-weight: 400;
	font-style: normal;
	color: var(--main-color-yellow);
}

.home-header img {
	width: 100%;
	max-width: 300px;
}

.home-header-box h2 {
	font-size: 2em;
	font-weight: 400;
	margin: 10px 0 0 0;
	text-align: start;
}