.carousel-box-centered {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
}

.carousel {
	max-width: 800px;
	max-height: 500px;
	width: 100%;
	height: 100%;
}

.carousel-image {
	object-fit: cover;
}